import {mapActions, mapGetters, mapMutations} from "vuex";
import {FETCH_USER_DATA, LINK_WITH_GOOGLE, SING_IN_WITH_GOOGLE} from '@/store/action-types';
import Vue from 'vue';
import GSignInButton from 'vue-google-signin-button';

Vue.use(GSignInButton);

export default {
  name: 'google-widget',
  data() {
    return {
      // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
      googleSignInParams: {
        client_id: process.env.VUE_APP_GOOGLE_IDENTITY
      },
    }
  },
  props: ['title'],
  components: {
    GSignInButton
  },
  methods: {
    ...mapActions({
      auth: `auth/${SING_IN_WITH_GOOGLE}`,
      link: `profile/${LINK_WITH_GOOGLE}`,
      fetchUser: `profile/${FETCH_USER_DATA}`,
      fetchFavouriteList: `favorites/GET_FAVORITES_LIST`,
    }),
    onSignInSuccess(googleUser) {
      const token = googleUser.getAuthResponse('access_token').access_token;

      if (this.$route.fullPath.includes('sign-in')
        || this.$route.fullPath.includes('register')
        || this.$route.fullPath.includes('favorites')
      ) {
        this.auth({token: token, provider: 'google'})
          .then(() => {
            this.fetchUser().then(() => {
              try {
                this.$router.push({name: 'profile'}).catch(() => {console.log()});
                this.fetchFavouriteList();
              } catch (e) {
                console.log(e);
              }
            });
            this.$toasted.success(this.$t('successAuth'));
          })
          .catch(error => {
            console.log(error);
            if (error.status === 418) {
              this.$toasted.error(error.data.message);
            }
          })
      }
      if (this.$route.name.includes('buy-address')) {
        this.auth({token: token, provider: 'google'})
          .then(() => {
            this.fetchUser().then(() => {
              this.$toasted.success(this.$t('successAuth'));
            })
          })
          .catch(error => {
            if (error.status === 418) {
              this.$toasted.error(error.data.message);
            }
          })
      }
      if (this.$route.name.includes('user-data')) {
        this.link({token: token, provider: 'google'})
          .then(() => {
            this.fetchUser().then(() => {
              this.$toasted.success(this.$t('successLinkToAccount'));
              this.fetchFavouriteList();
            })
          })
          .catch(error => {
            if (error.status === 418) {
              this.$toasted.error(error.data.message);
            }
          })
      }

    },
    onSignInError(error) {
      console.log(error);
    }
  }
}
