import {mapActions, mapGetters, mapMutations} from "vuex";
import facebook from '@/components/widgets/facebook/index.vue';
import google from '@/components/widgets/google/index.vue';
import mainButton from '@/components/main-button/index.vue';

import {validationMixin} from 'vuelidate'
import {maxLength, minLength, required, email, sameAs} from "vuelidate/lib/validators";
//sections
export default {
  name: "register",
  mixins: [validationMixin],
  components:{
    mainButton,
    facebook,
    google
  },
  data(){
    return{
      see: false,
      repeatSee: false,
      status: false,
      payload: {
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        password: '',
        password_confirmation: '',
        agreement: false,
      },
      validationErrors: {},
      vueTel: {
        phone: '',
        props: {
          maxLen: 11,
          validCharactersOnly: true,
          mode: "international",
          dynamicPlaceholder: 'true',
          disabledFetchingCountry: true,
          disabled: false,
          required: true,
          enabledCountryCode: false,
          enabledFlags: true,
          onlyCountries: [],
          ignoredCountries: [],
          autocomplete: "off",
          name: "telephone",
          inputClasses: "",
          inputOptions: {
            showDialCode: true,
          },
          disabledFormatting: true,
          wrapperClasses: "input-row"
        }
      },
    }
  },
  validations: {
    payload: {
      first_name: {
        required,
        maxLength: maxLength(25),
        minLength: minLength(2),
      },
      last_name: {
        required,
        maxLength: maxLength(25),
        minLength: minLength(2)
      },
      phone: {
        required,
        maxLength: maxLength(18),
        minLength: minLength(7),
      },
      email: {
        email,
        maxLength: maxLength(30),
        required,
      },
      password: {
        required,
        maxLength: maxLength(25),
        minLength: minLength(8),
      },
      password_confirmation: {
        maxLength: maxLength(25),
        minLength: minLength(8),
        sameAsPassword: sameAs('password')
      },
    },
  },
  created() {

  },
  computed:{
    ...mapGetters({
      loadingRegistration: 'auth/loadingRegistration'
    }),
    firstNameError() {
      const error = []
      if (!this.$v.payload.first_name.$dirty) {
        return error
      }
      if (!this.$v.payload.first_name.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.first_name.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.first_name.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.first_name) {
        this.validationErrors.first_name.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    lastNameError() {
      const error = []
      if (!this.$v.payload.last_name.$dirty) {
        return error
      }
      if (!this.$v.payload.last_name.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.last_name.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.last_name.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 2))
      }
      if (this.validationErrors.last_name) {
        this.validationErrors.last_name.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    phoneError() {
      const error = []
      if (!this.$v.payload.phone.$dirty) {
        return error
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.phone.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 15))
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 7))
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    emailError() {
      const error = []
      if (!this.$v.payload.email.$dirty) {
        return error
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('errorEmail'))
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 30))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    passwordError() {
      const error = []
      if (!this.$v.payload.password.$dirty) {
        return error
      }
      if (!this.$v.payload.password.required) {
        error.push(this.$t('errorRequired'))
      }
      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('errorMaxLength').replace(':count', 25))
      }
      if (!this.$v.payload.password.minLength) {
        error.push(this.$t('errorMinLength').replace(':count', 8))
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row)
        })
        this.validationErrors = {}
      }
      return error
    },
    confirmPasswordError() {
      let error = [];
      if (!this.$v.payload.password_confirmation.$dirty) {
        return error;
      }
      if (!this.$v.payload.password_confirmation.sameAsPassword) {
        error.push(this.$t('validationSameAs'));
      }
      if (this.validationErrors.password_confirmation) {
        this.validationErrors.password_confirmation.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },

  methods:{
    ...mapActions({
      register: `auth/REGISTRATION`
    }),
    ...mapMutations({
    }),
    submitForm() {
      this.$v.$touch();
      if (!this.$v.payload.$invalid) {
        this.register(this.payload).then((result) => {
          this.status = !this.status
        }).catch(error => {
          if (error.status === 422) {
            this.$toasted.error(error.data.message);
            const errors = error.data.errors;
            for(const i in errors){
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
        })
      }
    }
  }
}
