import {mapActions, mapGetters, mapMutations} from "vuex";
import VFacebookLogin from "vue-facebook-login-component";

export default {
  name: 'facebook-widget',
  data() {
    return {
      facebook: {
        FB: {},
        model: {},
        appId: process.env.VUE_APP_FACEBOOK_IDENTITY,
      },
      updateRender: true,
    }
  },
  props: ['title'],
  components: {
    VFacebookLogin
  },
  methods: {
    ...mapActions({
      auth: `auth/SING_IN_WITH_FACEBOOK`,
      link: `profile/LINK_WITH_FACEBOOK`,
      fetchUser: `profile/FETCH_USER_DATA`,
      fetchFavouriteList: `favorites/GET_FAVORITES_LIST`,
    }),
    getUserData() {
      const {api} = this.facebook.FB;
      api('/me', {fields: 'id, name, email, first_name, last_name'}, (response) => {
        const token = this.facebook.FB.getAccessToken();
        console.log(this.$route.fullPath);
        if (this.$route.fullPath.includes('sign-in')
          || this.$route.fullPath.includes('registration')
        ) {
          console.log('asdasda');
          this.auth({token: token, provider: 'facebook'})
            .then(() => {
              this.$toasted.success(this.$t('successAuth'));
              this.fetchUser().then(() => {
                this.$router.push({name: 'profile'}).catch(() => {console.log()});
                this.fetchFavouriteList();
              });
              this.handleLogout();
            })
            .catch(error => {
              if (error.status === 418) {
                this.$toasted.error(error.data.message);
              }
              this.handleLogout();
            })
        }
        if (this.$route.name.includes('buy-address')) {
          this.auth({token: token, provider: 'facebook'})
            .then(() => {
              this.fetchUser().then(() => {
                this.$toasted.success(this.$t('successAuth'));
              })
            })
            .catch(error => {
              if (error.status === 418) {
                this.$toasted.error(error.data.message);
              }
            })
        }
        if (this.$route.name.includes('user-data')) {
          this.link({token: token, provider: 'facebook'})
            .then(() => {
              this.fetchUser().then(() => {
                this.fetchFavouriteList();
                this.$toasted.success(this.$t('successLinkToAccount'));
                this.handleLogout();
              })
            })
            .catch(error => {
              if (error.status === 418) {
                this.$toasted.error(error.data.message);
              }
              this.handleLogout();
            })
        }
      })
    },
    handleSdkInit({FB}) {
      FB.init({
        xfbml: true,
        version: 'v2.8',
        appId: process.env.VUE_APP_FACEBOOK_IDENTITY,
        status: false,
        cookie: false
      });
      this.facebook.FB = FB;
    },
    mounted() {
      this.handleLogout();
    },
    handleConnect() {

    },
    handleLogout() {
      this.facebook.FB.getLoginStatus(response => {
        if (response.status === 'connected') {
          this.facebook.FB.logout(resp => {
            this.updateRender = false;
            setTimeout(() => {
              this.updateRender = true;
            }, 10)
          });
        }
      })
    },
    login() {
      this.getUserData();
    },
    handleClick() {
      // eslint-disable-next-line
    },
  },
  destroyed() {
    this.handleLogout();
  }
}
